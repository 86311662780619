import React from 'react'
import PropTypes from 'prop-types'
import Title from 'elements/Typography/Title'
import { navigate } from 'gatsby'
import { Box, SimpleGrid, Flex, Icon, Heading, Text } from '@chakra-ui/core'

function Feature ({ title, route, icon, desc, ...rest }) {
  return (
    <Flex onClick={() => navigate(route)} style={{ cursor: 'pointer' }} p={5} borderWidth="1px" flex="1" bg="white" rounded="md" {...rest}>
      <Box w={80}> <Icon name={icon} size="50px" color="gray.300"/></Box>
      <Box flex="1">
        <Heading fontSize="xl">{title}</Heading>
        <Text mt={4}>{desc}</Text>
      </Box>
    </Flex>
  )
}
Feature.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
}

function DocsPage (props) {
  return (
    <>

      <Title label="Overview">Documentation</Title>

      <SimpleGrid columns={2} spacing={10}>
        <Feature
          title="Getting started"
          icon="start"
          route="general/intro"
          desc="Overview of LotusEngine, core concepts, where to start and tutorials."
        />
        <Feature
          title="UI reference"
          icon="ui"
          route="ui/intro"
          desc="Dashboard documentation for workflow, collection and view creation."
        />
        <Feature
          title="Developer docs"
          icon="code"
          route="api/intro"
          desc="CLI and code reference for developers."
        />
        <Feature
          title="GraphQl docs"
          icon="api"
          route="api/intro"
          desc="Our GraphQL schema, authentication, etc..."
        />
      </SimpleGrid>

    </>
  )
}

DocsPage.propTypes = {}

export default DocsPage
